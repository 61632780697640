
.header {
  min-height: var(--header-height);
  padding-bottom: 15px;
  padding-top: 15px;

  @include grid-container;

  .header__inner {
    display: flex;
    grid-column: auto / span 12;
    justify-content: space-between;
  }

  .header__logo-wrapper {
    max-width: 208px;
  }

  .header__menu-bar {
    flex: 1;
  }

  @include notebook {
    .header__logo-wrapper {
      max-width: 238px;
    }

    .header__menu-bar {
      &:not(.header__menu-bar--no-logo) {
        margin-left: 8%;
      }
    }
  }
}
