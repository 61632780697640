
.news-cards {
  position: relative;

  .news-cards__grid {
    @include grid-container;
  }

  .news-cards__card {
    grid-column: auto / span 12;
  }

  .news-cards__title {
    @include typography(heading-3, false, true, false);

    color: var(--theme-color);
    grid-column: auto / span 12;
    margin-bottom: var(--section-space);
  }

  @include landscape {
    .news-cards__card {
      grid-column: auto / span 4;
    }
  }
}
