
.link {
  @include typography(link);

  cursor: pointer;
  display: inline;

  &:hover {
    .link__icon svg {
      margin-left: 5px;
    }
  }
}

button.link {
  .link__span {
    font-weight: 600;
  }
}

.link__icon {
  display: flex;

  svg {
    margin-left: 10px;
    max-height: 19.35px;
    max-width: 24px;
    transition: margin-left var(--transition-speed);
  }
}

.link--regular {
  //
}

.link--hero {
  --link-font-size: 16px;
  --link-font-style: normal;
  --link-font-variant: normal;
  --link-font-weight: #{get-montserrat-weight(regular)};
  --link-letter-spacing: -.005em;
  --link-line-height: 18px;
  --link-margin-bottom: 0;
  --link-margin-top: 15px;

  @include landscape {
    --link-font-size: 18px;
    --link-line-height: 22px;
  }

  &.link {
    align-items: center;
    color: var(--theme-color);
    display: inline-flex;
    text-transform: uppercase;
  }

  .link__span {
    text-decoration: underline;
  }
}
