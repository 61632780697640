
.multiple-object-viewers {
  position: relative;

  .object-viewer,
  .object-viewer__container {
    aspect-ratio: auto;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: $z-index-full-screen;
  }

  .object-viewer {
    display: none;

    &.object-viewer--fullscreen {
      display: block;
    }

    &:not(.object-viewer--active) {
      display: none;
    }
  }

  .button--open {
    float: right;
    margin-bottom: 1rem;
  }
}
