
:root {
  --heading-4-font-stack: #{$montserrat-font-stack};
  --heading-4-font-size: 16px;
  --heading-4-font-style: normal;
  --heading-4-font-variant: normal;
  --heading-4-font-weight: bold;
  --heading-4-letter-spacing: -.02em;
  --heading-4-line-height: 18px;
  --heading-4-margin-bottom: 10px;
  --heading-4-margin-top: 0;
  --heading-4-color: #{var(--theme-color)};
  --heading-4-text-transform: uppercase;

  @include landscape {
    --heading-4-line-height: 28px;
    --heading-4-font-size: 24px;
  }
}
