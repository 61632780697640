
.explore-cards {
  margin-bottom: var(--section-space);

  --explore-space: 30px;

  .explore-cards__grid {
    @include grid-container;
  }

  @include breakpoints(mobile-only, portrait-only) {
    .explore-cards__card {
      display: block;
      margin-bottom: var(--explore-space);

      &:nth-child(odd) {
        grid-column: auto / span 7;
      }

      &:nth-child(even) {
        grid-column: column 6 / span 7;
      }

      &:nth-child(1) {
        // If the first card is a story, than the next card should not have the overlap grid style
        &.explore-cards__card--story {
          + .explore-cards__card--object {
            margin-top: 0;
          }
        }

        .card--object,
        .card--story {
          .card__content-wrapper {
            --grid-columns: 7;

            @include subgrid-container;
          }

          .card__content-inner {
            grid-column: auto / span 5;
          }
        }
      }

      &:nth-child(2) {
        margin-top: -165px;
      }
    }
  }

  @include landscape {
    .explore-cards__card {
      display: block;
      grid-column: auto / span 4;
      margin-bottom: 50px;

      &:nth-child(-n+3) {
        grid-row: 1;
      }

      &:nth-child(n+4) {
        grid-row: 2;
      }

      &:nth-child(1) {
        grid-column: column 1 / span 4;

        // If the first card is a story, than the next card should not have the overlap grid style
        &.explore-cards__card--story {
          + .explore-cards__card--object {
            grid-column: auto / span 4;
          }
        }

        .card--object,
        .card--story {
          .card__content-wrapper {
            --grid-columns: 4;

            @include subgrid-container;
          }

          .card__content-inner {
            grid-column: auto / span 3;
          }
        }
      }

      &:nth-child(2) {
        grid-column: column 4 / span 4;
        margin-top: calc(var(--explore-space) * 2);
        z-index: 2;
      }

      &:nth-child(3) {
        grid-column: column 9 / span 4;
      }

      &:nth-child(4) {
        margin-top: var(--explore-space);
      }

      &:nth-child(6) {
        margin-top: calc(var(--explore-space) * 2);
      }
    }
  }
}
