
.images__grid {
  $images-offset: 40px;

  @include grid-container;

  margin-bottom: var(--section-space);

  .image {
    &:nth-child(1) {
      grid-column: column 1 / span 8;
      grid-row: 1;
      margin-top: $images-offset;

      .figure__caption-wrapper {
        --grid-columns: 8;

        @include subgrid-container;
      }

      .figure__caption {
        grid-column: column 1 / span 7;
        margin-bottom: 20px;
      }
    }

    &:nth-child(2) {
      grid-column: column 8 / span 5;
      grid-row: 1;

      .figure__caption-wrapper {
        --grid-columns: 5;

        @include subgrid-container;
      }

      .figure__caption {
        grid-column: column 2 / span 4;
      }
    }

    &:nth-child(3) {
      grid-column: column 4 / span 6;
      grid-row: 2;
      margin-bottom: 40px;
    }

    &:nth-child(4) {
      grid-column: column 1 / span 7;
      grid-row: 3;

      .figure__caption__wrapper {
        --grid-columns: 7;

        @include subgrid-container;
      }

      .figure__caption {
        grid-column: column 1 / span 6;
      }
    }

    &:nth-child(5) {
      grid-column: column 7 / span 6;
      grid-row: 3;
      margin-top: $images-offset;
    }
  }

  @include landscape {
    .image {
      &:nth-child(1) {
        .figure__caption {
          @include landscape {
            grid-column: column 1 / span 4;
            margin-bottom: 0;
          }
        }
      }

      &:nth-child(3) {
        grid-column: column 7 / span 4;
      }

      &:nth-child(4) {
        grid-column: column 1 / span 6;

        .figure__caption__wrapper {
          --grid-columns: 6;
        }

        .figure__caption {
          grid-column: column 1 / span 5;
        }
      }

      &:nth-child(5) {
        grid-column: column 6 / span 6;
      }
    }
  }
}
