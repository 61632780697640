
.places-map {
  height: 100vh;
  padding-bottom: 30px;
  padding-top: 30px;
  position: relative;

  &::before {
    background: linear-gradient(0deg, rgb(255 255 255 / 100%) 0%, rgb(255 255 255 / 0%) 100%);
    bottom: 0;
    content: '';
    display: block;
    height: 300px;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .places-map__grid {
    @include grid-container;

    position: relative;
    z-index: 2;
  }

  .places-map__title {
    @include typography(heading-2);

    color: var(--theme-color);
    grid-column: auto / span 12;
    margin-bottom: 20px;
  }

  .places-map__subtitle {
    @include typography(heading-5, true);

    color: var(--theme-color);
    grid-column: auto / span 12;
    transform: translateY(-20px);
  }

  .places-map__map {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    &::after {
      background-image: linear-gradient($color-white 40%, rgb(255 255 255 / .1%));
      content: '';
      display: block;
      height: 150px;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .places-map__svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .places-map__zoom-button {
    background: transparent;
    border: 1px solid $color-magenta;
    border-radius: 50%;
    color: $color-magenta;
    height: 40px;
    left: 20px;
    position: absolute;
    transition: background-color $easing-change, color $easing-change;
    width: 40px;

    &:hover {
      background: $color-magenta;
      color: $color-white;
    }

    &:disabled,
    &:hover:disabled {
      background-color: transparent;
      border-color: rgb(0 0 0 / 50%);
      color: rgb(0 0 0 / 500%);
      pointer-events: none;
    }
  }

  .places-map__zoom-button--out {
    bottom: 40px;
  }

  .places-map__zoom-button--in {
    bottom: 90px;
  }

  @include breakpoints(mobile-only, portrait-only) {
    &.places-map--zoomed-in {
      .places-map__svg {
        height: 200%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 200%;
      }
    }
  }

  @include landscape {
    .places-map__map {
      &::after {
        height: 250px;
      }
    }

    .places-map__zoom-button {
      display: none;
    }
  }
}
