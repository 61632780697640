
.figure {
  contain: content;
  position: relative;

  .picture {
    height: 100%;
    width: 100%;
  }

  .figure__caption {
    @include typography(caption, true, true);

    color: var(--theme-color);
    margin-top: 15px;
    padding-left: 25px;
    position: relative;
  }

  .figure__caption-number {
    left: 0;
    position: absolute;
    top: 0;
  }
}
