
:root {
  --heading-2-font-stack: #{$montserrat-font-stack};
  --heading-2-font-size: 28px;
  --heading-2-font-style: normal;
  --heading-2-font-variant: normal;
  --heading-2-font-weight: #{get-montserrat-weight(bold)};
  --heading-2-letter-spacing: 0;
  --heading-2-line-height: 36px;
  --heading-2-margin-bottom: 15px;
  --heading-2-margin-top: 0;
  --heading-2-color: #{var(--theme-color)};
  --heading-2-text-transform: uppercase;

  @include landscape {
    --heading-2-font-size: 56px;
    --heading-2-line-height: 58px;
  }
}
