
.footer {
  @include container;

  display: flex;
  font-family: $montserrat-font-stack;
  font-size: 14px;
  font-weight: #{get-montserrat-weight(normal)};
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;

  .footer__links {
    //
  }

  .footer__link {
    color: $color-black;
    margin-right: 20px;
  }
}
