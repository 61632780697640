
.article-video {
  margin-bottom: var(--section-space);

  .article-video__grid {
    @include grid-container;
  }

  .article-video__figure {
    grid-column: auto / span 12;
  }

  .article-video__caption-wrapper {
    grid-column: auto / span 12;
  }

  .article-video__caption {
    @include typography(caption, true, true);

    color: var(--theme-color);
    margin-top: 15px;
    padding-left: 25px;
    position: relative;
  }

  .article-video__caption-number {
    left: 0;
    position: absolute;
    top: 0;
  }

  @include landscape {
    .article-video__figure {
      grid-column: column 2 / span 10;
    }

    .article-video__caption-wrapper {
      grid-column: column 2 / span 10;
    }
  }
}
