
.news-list-intro {
  background-color: var(--theme-color);
  color: $color-white;
  margin-bottom: calc(var(--section-space) / 2);
  padding-bottom: 40px;
  padding-top: var(--section-space);

  .news-list-intro__grid {
    @include grid-container;
  }

  .news-list-intro__title {
    @include typography(heading-1, false, true, false);

    color: inherit;
    grid-column: auto / span 12;
  }

  .news-list-intro__post {
    grid-column: auto / span 12;

    @include subgrid-container;
  }

  .news-list-intro__post-title {
    @include typography(heading-3, false, false, false);

    color: inherit;
    grid-column: auto / span 9;
    margin-bottom: -15px;
    position: relative;
    z-index: $z-index-over-gradient;
  }

  .picture {
    grid-column: auto / span 12;
    margin-bottom: 30px;
    position: relative;

    &::before {
      background: linear-gradient(to bottom, rgb(0 0 0 / 50%), rgb(0 0 0 / 0%) 100%);
      content: '';
      height: 50px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $z-index-over-image;
    }
  }

  .link {
    align-items: center;
    display: flex;
    line-height: initial;
    margin-bottom: 20px;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .news-list-intro__post-excerpt {
    @include typography(paragraph, $lora-font-stack, false);

    color: inherit;
  }

  .news-list-intro__post-content {
    grid-column: auto / span 12;
  }

  .news-list-intro__post-date {
    @include typography(date, true, false, false);

    display: block;
  }

  @include portrait {
    .news-list-intro__post-excerpt {
      --paragraph-font-size: 16px;
      --paragraph-line-height: 22px;
    }
  }

  @include landscape {
    padding-bottom: 20px;

    .news-list-intro__post {
      grid-column: auto / span 9;

      --grid-columns: 9;
    }

    .news-list-intro__post-title {
      grid-column: auto / span 3;
      grid-row: 1;
      margin-bottom: -21px;
    }

    .picture {
      grid-column: auto / span 6;
      grid-row: 2;
      margin-bottom: 0;
    }

    .news-list-intro__post-content {
      align-self: flex-end;
      grid-column: auto / span 3;
      grid-row: 2;
    }
  }
}
