
.menu-bar {
  position: relative;

  .menu-bar__wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .menu-bar__menu-btn {
    margin-left: auto;

    .menu-bar__menu-btn__icon--open {
      width: 36px;

      .menu-bar__menu-btn--open & {
        display: none;
      }
    }

    .menu-bar__menu-btn__icon--close {
      display: none;
      width: 27px;

      .menu-bar__menu-btn--open & {
        display: inline-block;
      }
    }
  }

  .menu-bar__links {
    display: none;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;

    &.menu-bar__links--open {
      background-color: $color-white;
      bottom: 0;
      display: flex;
      flex-direction: column;
      left: 0;
      min-height: calc(100vh - var(--header-height));
      padding: 30px var(--grid-margin) 0 var(--grid-margin);
      position: absolute;
      right: 0;
      top: 58px;
      z-index: $z-index-menu-overlay;
    }
  }

  .menu-bar__menu-item {
    margin-bottom: 30px;

    &:last-child {
      margin-top: auto;
    }

    .link {
      &:not(:hover) {
        text-decoration: none;
      }
    }

    .link__span {
      font-family: $montserrat-font-stack;
      font-size: 24px;
      font-weight: #{get-montserrat-weight(bold)};
      text-transform: uppercase;
    }
  }

  @include notebook {
    .menu-bar__wrapper {
      display: block;
    }

    .menu-bar__menu-btn {
      display: none;
    }

    .menu-bar__links {
      display: flex;
    }

    .menu-bar__menu-item {
      margin-bottom: 0;

      &:last-child {
        margin-top: 0;
      }
    }
  }
}
