
// Styles

// Visually disable the size when the displayport is too small
@mixin style-minimum-width-blackout {
  &::after {
    background: rgb(255 255 255 / 80%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-website-min-width-overlay;
  }
}

@mixin style-anchor($inverted: false) {
  cursor: pointer;
  text-decoration: none;
  text-decoration-skip-ink: auto; // Pretty descenders! 😍
  transition: color $easing-change;

  > span {
    text-decoration: if($inverted, none, underline);
  }

  &:hover {
    text-decoration: none;

    > span {
      text-decoration: if($inverted, underline, none);
    }
  }
}

@mixin style-anchor-plain($inverted: false) {
  cursor: pointer;
  text-decoration: if($inverted, none, underline);
  text-decoration-skip-ink: auto; // Pretty descenders! 😍
  transition: color $easing-change;

  &:hover {
    text-decoration: if($inverted, underline, none);
  }
}

@mixin style-list-base {
  font-size: var(--paragraph-font-size);
  line-height: var(--paragraph-line-height);
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    --list-spacing: 40px;

    @include landscape {
      --list-spacing: 50px;
    }

    padding-left: var(--list-spacing);
    position: relative;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &::before {
      color: var(--theme-color);
      font-size: 22px;
      left: 0;
      left: calc(var(--list-spacing) / 2);
      position: absolute;
      top: 0;
      transform: translateX(-50%);
    }
  }
}

@mixin style-list-unordered {
  li {
    &::before {
      content: '•';
      font-size: 24px;
    }
  }
}

@mixin style-list-ordered {
  counter-reset: list-counter;

  li {
    counter-increment: list-counter;

    &::before {
      content: counter(list-counter);
      font-family: $montserrat-font-stack;
      font-size: 18px;
      font-weight: #{get-montserrat-weight(bold)};
    }
  }
}
