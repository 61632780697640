
.content-and-image {
  margin-bottom: var(--section-space);

  .content-and-image_grid {
    @include grid-container;
  }

  .content-and-image__image-outer {
    display: flex;
    flex-direction: column;
    grid-column: column 2 / span 12;
  }

  .content-and-image__text {
    grid-column: auto / span 12;
    margin-bottom: var(--section-space);
  }

  .content-and-image__image__image-wrapper {
    flex-grow: 1;
  }

  @include landscape {
    margin-bottom: 0;

    .content-and-image__image-outer {
      grid-column: column 9 / span 4;
    }

    .content-and-image__text {
      grid-column: column 2 / span 6;
    }
  }
}
