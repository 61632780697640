
:root {
  --heading-6-font-stack: #{$montserrat-font-stack};
  --heading-6-font-size: 16px;
  --heading-6-font-style: normal;
  --heading-6-font-variant: normal;
  --heading-6-font-weight: normal;
  --heading-6-line-height: 18px;
  --headinh-6-letter-spacing: -.04em;
  --heading-6-margin-bottom: 10px;
  --heading-6-margin-top: 0;

  @include landscape {
    --heading-6-line-height: 22px;
    --heading-6-font-size: 18px;
  }
}
