
.number-button {
  color: var(--theme-color);
  font-family: $montserrat-font-stack;
  font-size: 16px;
  font-weight: #{get-montserrat-weight(bold)};
  line-height: 18px;

  &:hover {
    text-decoration: underline;
  }

  .number-button__title {
    color: inherit;
  }

  .number-button__icon {
    color: inherit;
    display: block;

    svg {
      vertical-align: sub;
      width: 24px;
    }
  }

  @include landscape {
    font-size: 18px;
    line-height: 22px;

    .number-button__title {
      padding: 0 8px;
    }
  }
}

.number-button--clean {
  &:hover {
    text-decoration: underline;
  }

  &.number-button--next {
    margin-left: 20px;
  }

  &.number-button--previous {
    margin-right: 20px;
  }

  @include landscape {
    &.number-button--next {
      margin-left: 100px;
    }

    &.number-button--previous {
      margin-right: 100px;
    }
  }
}

.number-button--selected {
  text-decoration: underline;
}
