
:root {
  --sub-heading-font-stack: #{$montserrat-font-stack};
  --sub-heading-font-size: 16px;
  --sub-heading-font-style: normal;
  --sub-heading-font-variant: normal;
  --sub-heading-font-weight: #{get-montserrat-weight(regular)};
  --sub-heading-letter-spacing: -.005em;
  --sub-heading-line-height: 1.25em;
  --sub-heading-margin-bottom: .5em;
  --sub-heading-margin-top: 1em;

  @include portrait {
    --sub-heading-font-size: 18px;
    --sub-heading-line-height: 1.75em;
  }

  @include landscape {
    //
  }
}
