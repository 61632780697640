
.animate-section {
  height: 100vh;
  overflow: hidden;

  &:first-child {
    height: calc(100vh - var(--header-height));
  }

  .animate-section__inner,
  .animate-section__outer {
    height: 100%;
    width: 100%;
  }
}
