
.object-viewer {
  background-color: $color-grey-new;
  color: $color-black;
  cursor: grab;
  height: 100%;
  position: relative;
  width: 100%;

  .object-viewer__container {
    background-color: $color-grey-new;
    background-image: radial-gradient(ellipse at bottom, $color-grey-new 0%, $color-white 100%);
    color: $color-black;
    height: 100%;
    position: relative;
    width: 100%;
  }

  .object-viewer__canvas {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .object-viewer__progress {
    @include typography(heading-5);

    color: var(--theme-color, $color-magenta);
    height: 1em;
    left: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  .object-viewer__title {
    @include typography(heading-6);

    color: $color-black;
    left: 5rem;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 2.5rem;
    transition: opacity $easing-change;
    width: calc(100% - 10rem);

    &.object-viewer__title--visible {
      opacity: 1;
    }
  }

  .button--info {
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 5.5rem;
    transform: translate(-50%, -2rem);
    transition: opacity $easing-change, transform $easing-change;
    vertical-align: top;

    .button__icon {
      animation: infobuttonicon 2s infinite;
    }

    &.button--visible {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  .button--close {
    display: none;
    min-width: unset;
    padding: 9px;
    position: absolute;
    right: 2rem;
    top: 2rem;

    .button__icon {
      margin-right: 0;
    }
  }

  .thumbnail-list {
    bottom: 1rem;
    left: 1rem;
    max-width: calc(100% - 2rem);
    pointer-events: none;
    position: absolute;
    width: auto;

    .thumbnail-list__thumbnail {
      pointer-events: initial;
    }
  }

  &.object-viewer--grabbing {
    cursor: grabbing;
  }

  &.object-viewer--fullscreen {
    display: none;
    left: 0;
    position: fixed;
    top: 0;
    z-index: $z-index-full-screen;

    &.object-viewer--active {
      display: block;

      .button--close {
        display: block;
      }
    }
  }

  @include landscape {
    .object-viewer__title {
      left: 1rem;
      text-align: left;
      width: 33vw;
    }

    .button--info {
      top: 2rem;
    }
  }
}
