
:root {
  --heading-5-font-stack: #{$montserrat-font-stack};
  --heading-5-font-size: 16px;
  --heading-5-font-style: normal;
  --heading-5-font-variant: normal;
  --heading-5-font-weight: bold;
  --heading-5-letter-spacing: .01em;
  --heading-5-line-height: 18px;
  --heading-5-margin-bottom: 10px;
  --heading-5-margin-top: 0;
  --heading-5-color: #{var(--theme-color)};
  --heading-5-text-transform: uppercase;

  @include landscape {
    --heading-5-line-height: 22px;
    --heading-5-font-size: 18px;
  }
}
