
.pagination {
  margin-bottom: var(--section-space);
  position: relative;

  .pagination__grid {
    @include grid-container;
  }

  .pagination__wrapper {
    align-items: center;
    display: flex;
    grid-column: column 1 / span 12;
    justify-content: space-between;
  }

  @include landscape {
    .pagination__wrapper {
      justify-content: center;
    }
  }
}
