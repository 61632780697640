
.panorama-viewer {
  background-color: $color-grey-new;
  color: $color-black;
  cursor: grab;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-full-screen;

  .panorama-viewer__container {
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;

    .psv-container {
      background-color: $color-grey-new;
      color: $color-black;
    }

    .psv-navbar {
      display: none;
    }

    .psv-overlay-image {
      svg {
        margin-bottom: 0;
        width: 5rem;
      }
    }

    .psv-overlay-title {
      @include typography(heading-5);

      max-width: 20ch;
      text-align: center;
    }

    .psv-loader-canvas {
      > circle {
        fill: var(--theme-color, $color-magenta);
      }

      > path {
        stroke: $color-white;
      }
    }
  }

  .thumbnail-list {
    bottom: 1rem;
    left: 1rem;
    max-width: calc(100% - 2rem);
    pointer-events: none;
    position: absolute;
    width: auto;

    .thumbnail-list__thumbnail {
      pointer-events: initial;
    }
  }

  .panorama-viewer__title {
    @include typography(heading-6);

    color: $color-white;
    left: 5rem;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 2.5rem;
    transition: opacity $easing-change;
    width: calc(100% - 10rem);

    &.panorama-viewer__title--visible {
      opacity: 1;
    }
  }

  .button--info {
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 5.5rem;
    transform: translate(-50%, -2rem);
    transition: opacity $easing-change, transform $easing-change;
    vertical-align: top;

    &.button--visible {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    .button__icon {
      animation: infobuttonicon 2s infinite;
    }
  }

  .button--close {
    min-width: unset;
    padding: 9px;
    position: absolute;
    right: 2rem;
    top: 2rem;

    .button__icon {
      margin-right: 0;
    }
  }

  &.panorama-viewer--active {
    display: block;
  }

  &.panorama-viewer--grabbing {
    cursor: grabbing;
  }

  @include landscape {
    .panorama-viewer__title {
      left: 1rem;
      text-align: left;
      width: 33vw;
    }

    .button--info {
      top: 2rem;
    }
  }
}
