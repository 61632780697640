
:root {
  --caption-font-stack: #{$montserrat-font-stack};
  --caption-font-size: 16px;
  --caption-font-style: normal;
  --caption-font-variant: normal;
  --caption-font-weight: #{get-montserrat-weight(regular)};
  --caption-letter-spacing: -.005em;
  --caption-line-height: 18px;
  --caption-margin-bottom: 0;
  --caption-margin-top: 15px;

  @include landscape {
    //
  }
}
