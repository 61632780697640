
:root {
  --quote-font-stack: #{$montserrat-font-stack};
  --quote-font-size: 20px;
  --quote-font-style: normal;
  --quote-font-variant: normal;
  --quote-font-weight: #{get-montserrat-weight(regular)};
  --quote-letter-spacing: 0;
  --quote-line-height: 1.5em;
  --quote-margin-bottom: .5em;
  --quote-margin-top: 1em;

  @include portrait {
    --quote-font-size: 25px;
  }

  @include landscape {
    //
  }
}
