
:root {
  --date-font-stack: #{$montserrat-font-stack};
  --date-font-size: 16px;
  --date-font-style: normal;
  --date-font-variant: normal;
  --date-font-weight: #{get-montserrat-weight(normal)};
  --date-letter-spacing: 0;
  --date-line-height: 18px;
  --date-margin-bottom: 20px;
  --date-margin-top: 20px;
}
