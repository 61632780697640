@use 'sass:string';

// Grid variables
//
// You can add or remove breakpoints at will,
// but be sure to add/remove/modify the relevant mixins as well!

:root {
  --breakpoint-mobile: #{$mobile};
  --breakpoint-portrait: #{$portrait};
  --breakpoint-landscape: #{$landscape};
  --breakpoint-notebook: #{$notebook};
  --breakpoint-desktop: #{$desktop};
  --section-space: 60px;
  --breakpoint: var(--breakpoint-mobile);
  --document-min-width: #{$min-width};
  --document-max-width: #{$max-width};
  --grid-columns: 12;
  --grid-gutter: 20px;
  --grid-spacing: 0;
  --grid-margin: 20px;
  --grid-max-width: 100%;
  --margin-md: 20px;
  --margin-lg: 40px;
  --margin-xl: 70px;
  --beat: 10px;
  --transition-speed: .2s;

  @include portrait {
    --breakpoint: var(--breakpoint-portrait);
    --grid-gutter: 20px;
    --grid-spacing: 0;
    --grid-margin: 30px;
    --margin-md: 30px;
    --margin-lg: 40px;
    --margin-xl: 70px;
  }

  @include landscape {
    --breakpoint: var(--breakpoint-landcape);
    --section-space: 100px;
    --grid-gutter: 25px;
    --grid-spacing: 0;
    --grid-margin: 60px;
    --margin-md: 30px;
    --margin-lg: 60px;
    --margin-xl: 100px;
  }

  @include notebook {
    --breakpoint: var(--breakpoint-notebook);
    --grid-gutter: 25px;
    --grid-spacing: 0;
    --grid-margin: 120px;
    --grid-max-width: 1360px;
    --margin-md: 30px;
    --margin-lg: 60px;
  }

  @include desktop {
    --breakpoint: var(--breakpoint-desktop);
    --grid-gutter: 25px;
    --grid-spacing: 0;
    --grid-margin: 240px;
    --grid-max-width: 100%;
    --margin-md: 30px;
    --margin-lg: 60px;
  }
}

@mixin container() {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: var(--grid-gutter);
  padding-right: var(--grid-gutter);
}

@mixin grid-container($columns: 'repeat(var(--grid-columns), [column] minmax(0, 1fr))') {
  display: grid;
  grid-gap: var(--grid-spacing) var(--grid-gutter);
  grid-template-columns: string.unquote($columns);

  @include container;
}

@mixin subgrid-container($columns: 'repeat(var(--grid-columns), [column] minmax(0, 1fr))') {
  display: grid;
  grid-gap: var(--grid-spacing) var(--grid-gutter);
  grid-template-columns: string.unquote($columns);
}
