
.video-embed {
  height: auto;
  position: relative;
  width: 100%;

  .video-embed__container {
    overflow: hidden;
    position: relative;
    width: 100%;

    .picture {
      height: auto;
      opacity: 1;
      transition: opacity .3s ease var(--transition-speed);
      width: 100%;
    }
  }

  .video-embed__caption {
    @include typography(caption, true, true);
  }

  .video-embed__video,
  .video-embed__hit-target {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .video-embed__overlay {
    background-color: var(--theme-color);
    bottom: 0;
    display: block;
    left: 0;
    opacity: .5;
    position: absolute;
    right: 0;
    top: 0;
  }

  .video-embed__video {
    background-color: $color-black;
  }

  .video-embed__hit-target {
    cursor: pointer;
  }

  .video-embed__button {
    display: none;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .video-embed__iframe,
  iframe {
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: opacity $easing-change-slower;
    width: 100%;
  }

  .video-embed__iframe--hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.video-embed--playing {
    .video-embed__hit-target {
      display: none;
    }
  }

  &:not(.video-embed--playing) {
    .video-embed__video {
      filter: brightness(.66) contrast(1.1);
    }

    .video-embed__button--play {
      color: $color-white;
      display: block;
      font-family: $montserrat-font-stack;
      font-size: 36px;
      font-weight: #{get-montserrat-weight(bold)};
      line-height: 36px;
      text-transform: uppercase;

      @include landscape {
        font-size: 56px;
        line-height: 58px;
      }
    }
  }
}
