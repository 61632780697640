
.richtext {
  //
}

.richtext--regular {
  h2 {
    @include typography(heading-2, true, true);

    color: var(--theme-color);

    &:not(:first-child) {
      margin-top: 50px;
    }
  }

  h3 {
    @include typography(heading-3, true, true);

    color: var(--theme-color);

    &:not(:first-child) {
      margin-top: 50px;
    }
  }

  h4 {
    @include typography(heading-4, true);

    color: var(--theme-color);

    &:not(:first-child) {
      margin-top: 50px;
    }
  }

  h5 {
    @include typography(heading-5, true);

    color: var(--theme-color);

    &:not(:first-child) {
      margin-top: 50px;
    }
  }

  p {
    @include typography(paragraph, $lora-font-stack, true);
  }

  ul,
  ol {
    @include style-list-base;

    margin-bottom: var(--paragraph-margin-bottom);
  }

  ul {
    @include style-list-unordered;
  }

  ol {
    @include style-list-ordered;
  }
}

.richtext--introduction {
  // NOT STYLED?
}

.richtext--simple {
  // NOT STYLED?
}
