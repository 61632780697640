
.home-content {
  height: 100vh;
  padding: var(--section-space) 0;

  .richtext {
    p {
      @include typography(introduction);

      color: var(--theme-color);
    }
  }
}

.home-content__grid {
  @include grid-container;
}

.home-content__wrapper {
  grid-column: column 1 / span 12;
}
