@use 'sass:meta';

// Typography
// How to use:
// 1. duplicate typography/_*.scss
// 2. change values therein
// 3. when applying, use @typography(name) (inside breakpoint is fine)

@mixin typography($name: '', $margin-top: false, $margin-bottom: false, $adaptive-margins: true) {
  // @if meta.mixin-exists($name) {
  //   @warn 'Typography mixin "#{$name}" does not exist';
  // } @else {

  color: var(--#{$name}-color);
  font-family: var(--#{$name}-font-stack);
  font-size: var(--#{$name}-font-size);
  font-style: var(--#{$name}-font-style);
  font-variant: var(--#{$name}-font-variant);
  font-weight: var(--#{$name}-font-weight);
  letter-spacing: var(--#{$name}-letter-spacing);
  line-height: var(--#{$name}-line-height);
  margin-left: var(--#{$name}-margin-left);
  text-transform: var(--#{$name}-text-transform);
  word-spacing: var(--#{$name}-word-spacing);

  @if $margin-top {
    @if $adaptive-margins {
      &:not(:first-child) {
        margin-top: var(--#{$name}-margin-top);
      }
    } @else {
      margin-top: var(--#{$name}-margin-top);
    }
  }

  @if $margin-bottom {
    @if $adaptive-margins {
      &:not(:last-child) {
        margin-bottom: var(--#{$name}-margin-bottom);
      }
    } @else {
      margin-bottom: var(--#{$name}-margin-bottom);
    }
  }
  //}
}
