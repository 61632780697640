
:root {
  --heading-1-font-stack: #{$montserrat-font-stack};
  --heading-1-font-size: 40px;
  --heading-1-font-style: normal;
  --heading-1-font-variant: normal;
  --heading-1-font-weight: #{get-montserrat-weight(bold)};
  --heading-1-letter-spacing: 0;
  --heading-1-line-height: 40px;
  --heading-1-margin-bottom: 30px;
  --heading-1-margin-top: 30px;
  --heading-1-margin-left: -3px;
  --heading-1-word-spacing: normal;
  --heading-1-text-transform: uppercase;

  @include landscape {
    --heading-1-font-size: 120px;
    --heading-1-line-height: 120px;
    --heading-1-margin-bottom: 50px;
    --heading-1-margin-left: -9px;
    --heading-1-margin-top: 50px;
  }
}
