
.object-detail-intro {
  align-items: flex-end;
  display: flex;
  margin-top: -30px;
  position: relative;
  z-index: $z-index-over-image;

  .object-detail-intro__titles {
    position: relative;
    transform: translate3d(0, 0, 0);
    transform-origin: bottom left;
  }

  .object-detail-intro__title {
    @include typography(heading-1, false, false, false);

    hyphens: auto;
  }

  .object-detail-intro__subtitle {
    @include typography(heading-3, true, true, false);
  }

  .object-detail-intro__title,
  .object-detail-intro__subtitle {
    color: var(--theme-color);
  }

  @include landscape {
    margin-bottom: var(--section-space);
    margin-top: 0;
    min-height: calc(100vh - var(--header-height));
  }
}
