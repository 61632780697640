
.button {
  --button-line-height: calc(var(--button-line-height) * .75);

  @include typography(button);

  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  max-width: min(100%, 520px);
  min-height: calc(var(--button-line-height) + 10px);
  min-width: 60px;
  padding: 5px 15px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  transition: color $easing-change, background-color $easing-change, border-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: auto;

  &::before {
    background: $color-blue;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    right: -15px;
    top: 0;
    transform: skewX(-30deg);
    width: 50px;
  }

  .button__span {
    display: block;
    flex: 1 1 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    z-index: 5;
  }

  .button__long-title {
    display: none;
    flex: 1 1 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    z-index: 5;

    @include landscape {
      display: block;
    }
  }

  .button__icon {
    display: block;
    flex: 0 0 var(--button-line-height);
    height: var(--button-line-height);
    width: var(--button-line-height);
    z-index: 5;

    > svg {
      display: block;
      fill: $color-white;
      height: var(--button-line-height);
      width: var(--button-line-height);

      > g {
        > g {
          fill: $color-white;
        }
      }
    }
  }

  .button__icon--before {
    margin-right: .5em;
  }

  .button__icon--after {
    margin-left: .5em;
    margin-right: -.5em;
  }
}

.button:disabled,
.button--disabled {
  cursor: default;
  opacity: .25;
  pointer-events: none;
}

.button--primary {
  background-color: $color-blue;
  border-color: $color-blue;
  color: $color-white;

  .button__icon {
    > svg {
      fill: $color-white;

      > g {
        > g {
          fill: $color-white;
        }
      }
    }
  }
}

.button--secondary {
  background-color: $color-white;
  border-color: $color-white;
  color: $color-blue;

  &::before {
    background: $color-white;
  }

  .button__icon {
    > svg {
      fill: $color-blue;

      > g {
        > g {
          fill: $color-blue;
        }
      }
    }
  }
}

.button--tertiary {
  background-color: var(--theme-color, $color-magenta);
  border-color: var(--theme-color, $color-magenta);
  color: $color-white;

  &::before {
    display: none;
  }

  .button__icon {
    > svg {
      fill: $color-white;

      > g {
        > g {
          fill: $color-white;
        }
      }
    }
  }
}

.button--info {
  background-color: rgba($color-white, .7);
  border-color: transparent;
  border-radius: 5px;
  color: $color-black;

  &::before {
    display: none;
  }

  .button__icon {
    > svg {
      fill: $color-black;

      > g {
        > g {
          fill: $color-black;
        }
      }
    }
  }
}

.button--outline {
  background-color: transparent;
  border-color: var(--theme-color, $color-magenta);
  border-style: solid;
  border-width: 2px;
  color: var(--theme-color, $color-magenta);

  &:hover,
  &:focus {
    background-color: var(--theme-color, $color-magenta);
    color: $color-white;
  }

  &::before {
    display: none;
  }

  .button__icon {
    > svg {
      fill: var(--theme-color, $color-magenta);

      > g {
        > g {
          fill: transparent;
        }
      }
    }
  }
}
