
.full-screen-logo {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;

  .full-screen-logo__meta {
    color: var(--theme-color);
    margin-top: var(--section-space);
    max-height: 50vh;
  }

  .full-screen-logo__hub {
    color: var(--theme-color);
    height: auto;
    max-width: 50vw;
  }

  .full-logo-frame--hidden {
    display: none;
  }

  @include landscape {
    align-items: initial;
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 75vw 25vw;
    justify-content: initial;

    .full-screen-logo__meta {
      margin-top: 0;
      max-height: initial;
    }

    .full-screen-logo__hub {
      margin-top: 50vh;
      max-width: auto;
    }
  }
}
