
.article-cookies {
  margin-bottom: var(--section-space);

  .article-cookies__grid {
    @include grid-container;
  }

  .article-cookies__wrapper {
    grid-column: column 1 / span 12;

    .link {
      color: $color-magenta;
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
      white-space: nowrap;

      .link__span {
        text-decoration: underline;
      }

      &:hover,
      &:focus {
        .link__span {
          text-decoration: none;
        }
      }

      &:not(:first-child) {
        margin-left: .5em;
      }
    }
  }

  .article-cookies__title,
  .option-field__input-label {
    @include typography(heading-2, true, true);
  }

  .article-cookies__text {
    @include typography(paragraph, $lora-font-stack, true);
  }

  @include landscape {
    .article-cookies__wrapper {
      grid-column: column 3 / span 7;
    }
  }
}
