
.object-detail {
  @include grid-container;

  margin-bottom: var(--section-space);

  .object-detail__main {
    grid-column: column 1 / span 12;
    order: 2;
  }

  .object-detail__text {
    --grid-columns: 12;

    @include subgrid-container;

    .richtext--regular {
      grid-column: auto / span 12;
      margin-bottom: var(--section-space);
    }
  }

  .object-detail__aside {
    grid-column: column 3 / span 10;
    order: 1;
    padding-top: 2rem;
    position: relative;
  }

  @include landscape {
    .object-detail__main {
      grid-column: column 1 / span 8;
      order: 1;
    }

    .object-detail__text {
      --grid-columns: 8;

      .richtext--regular {
        grid-column: auto / span 6;
      }
    }

    .object-detail__aside {
      grid-column: column 9 / span 4;
      order: 2;
      padding-top: 0;
    }
  }
}
