
.multiple-panorama-viewers {
  position: relative;

  .panorama {
    background-color: $color-black;
    color: $color-white;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-full-screen;

    &.panorama-viewer--fullscreen {
      display: block;
    }
  }

  .panorama__container {
    aspect-ratio: auto;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .button--open {
    bottom: 2rem;
    position: absolute;
    right: 2rem;
  }

  .multiple-panorama-viewers__thumbnail-list {
    bottom: 1rem;
    left: 1rem;
    position: absolute;
    width: calc(100% - 2rem);
  }
}
