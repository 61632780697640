
* {
  border-width: 0;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 0;
  //font-size: inherit;
  //line-height: inherit;

  ::before,
  ::after {
    box-sizing: border-box;
    //text-decoration: inherit;
    //vertical-align: inherit;
  }
}

:root {
  //overflow-y: auto; // All browsers without overlaying scrollbars - NOTE: Prevents navigation bar from collapsing on iOS
}

html {
  background-color: #fff;
  color: #000;
  //scroll-behavior: smooth; // NOTE: UIT! Want gruwlijk irritant als je naar een cached pagina terug tabbed, dan scrollt ie naar z'n oude plek
}

input[type='search'] {
  appearance: textfield; // Safari 8+

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    appearance: none; // Safari 8+
  }
}

a {
  text-decoration: none;
  text-decoration-skip-ink: auto; // Pretty descenders! 😍

  p &,
  li &,
  label & {
    color: inherit;
    text-decoration: underline;
  }
}

button {
  background: transparent;
  padding: 0;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

label {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

svg {
  fill: currentcolor;
  height: 100%;
  stroke: transparent;
  //stroke: currentColor;
  width: 100%;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

//[unselectable] {
//  user-select: none; // Android 4.4+, Chrome 40+, Firefox 36+, iOS 8+, Safari 8+
//}

// Opinionated defaults

// specify solid border style of all elements
//*,
//::before,
//::after {
//  border-style: solid;
//  border-width: 0;
//}

// remove margin and padding of all elements
// * {
//  margin: 0;
//  padding: 0;
//}

// specify the root styles of the document
//:root {
//  //box-sizing: border-box;
//  cursor: default;
//  font: 16px / 1.5 sans-serif;
//  text-rendering: optimizeLegibility;
//}

// specify the html background color
//html {
//  background-color: #fff;
//  color: #000;
//}

// specify the text decoration of anchors

// specify media element style
//audio,
//canvas,
//iframe,
//img,
//svg,
//video {
//  vertical-align: middle;
//}

// specify the background color of form elements
//button,
//input,
//select,
//textarea {
//  background-color: transparent;
//}

// specify inheritance of form elements
button,
input,
select,
textarea {
  border-radius: 0; // iOS
  color: inherit;
  font: inherit;
  min-height: 1em;
}

button,
select {
  &:not(:disabled) {
    cursor: pointer;
  }
}

select { // Firefox 36+
  appearance: none; // Chrome 41+
}

option {
  background: #fff;
  color: #000;
}

textarea {
  max-height: 50vh;
  resize: vertical;
}

// specify the font family of code elements
//code,
//kbd,
//pre,
//samp {
//  font-family: monospace;
//}

// specify the list style of nav lists
//nav {
//  ol,
//  ul {
//    list-style: none;
//  }
//}

// specify the border styles of tables
//table {
//  border-collapse: collapse;
//  border-spacing: 0;
//}

// specify text selection background color and omit drop shadow
::selection {
  background-color: #ede4e5; // required when declaring ::selection
  color: #000;
  text-shadow: none;
}

// hide content from screens but not screenreaders
@media screen {
  [hidden~='screen'] {
    display: inherit;
  }

  [hidden~='screen']:not(:active, :focus, :target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}

// Style HR
//hr {
//  background-color: currentColor;
//  height: 1px;
//  margin: 1em 0;
//}

// Remove spinner arrows
input[type='number'] {
  appearance: textfield;

  &::-webkit-inner-spin-button {
    display: none;
  }
}

// Remove X button
//input::-ms-clear {
//  display: none;
//}

// More normalization

//picture {
//  display: block;
//
//  &::after {
//    clear: both;
//    content: '';
//    display: table;
//  }
//}

//img {
//  max-width: 100%;
//  vertical-align: bottom;
//}

//ul {
//  list-style: none;
//  margin: 0;
//  padding: 0;
//}

figure,
picture {
  margin-left: 0;
  margin-right: 0;

  img,
  iframe,
  video,
  object {
    height: auto;
    width: 100%;
  }
}

//a,
//button,
//input[type='submit'],
//input[type='cancel'] {
//  cursor: pointer;
//}

//input,
//textarea,
//select {
//  // Fix webkit autocomplete
//  &:-webkit-autofill,
//  &:-webkit-autofill:hover,
//  &:-webkit-autofill:focus {
//    -webkit-box-shadow: none;
//    -webkit-text-fill-color: currentColor;
//    transition: background-color 5000s ease-in-out 0s;
//  }
//}

//input,
//input:matches([type='password'], [type='search']),
//textarea,
//input[type='range'],
//select {
//  -webkit-appearance: none; // iOS
//  border-radius: 0; // iOS
//  box-shadow: none; // iOS
//}

//sub,
//sup {
//  font-size: 85%;
//}

//p,
//li {
//  a {
//    color: inherit;
//    display: inline-block;
//    vertical-align: baseline;
//  }
//}

// Reset dropdown list colors, otherwise they tend to be invisible in Chrome on Windows
//option {
//  background-color: #fff;
//  color: #000;
//}

// Extra normalize styles

//h1,
//h2,
//h3,
//h4,
//h5,
//h6,
//strong,
//b {
//  // Reset font-weight so faux-bold doesn't slip in there
//  font-weight: inherit;
//}

//em,
//i {
//  // Reset font-style so faux-italic doesn't slip in there
//  font-style: inherit;
//}

//p,
//figure,
//blockquote,
//dt,
//dd,
//dl {
//  margin: 0;
//}

fieldset,
legend {
  margin: 0;
  padding: 0;
}

@keyframes focusanimation {
  0% {
    background-color: rgba(#39a935, .5);
  }

  100% {
    background-color: rgba(#39a935, .001);
  }
}

button,
summary,
a,
figcaption[tabindex='0'] {
  &:focus {
    //animation: 1s focusanimation ease-out 1;
    //outline: 2px solid #000;
    //outline-offset: 2px;
    outline: 1px solid #000;
  }
}

@media (hover: hover) {
  html:not(.key-pressed) {
    button,
    summary,
    a,
    [tabindex='0'],
    select {
      &:focus {
        outline: none;
      }
    }
  }
}

summary::-webkit-details-marker {
  display: none; // Chrome
}

summary {
  list-style: none; // Firefox
}

em,
i {
  font-style: inherit;
}

strong,
b {
  font-weight: inherit;
}

p,
ul,
ol {
  em,
  i {
    font-style: normal;
  }

  strong,
  b {
    font-weight: bold;
  }
}

// WCAG max text length
h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  //max-width: 80ch;
}
