
:root {
  --link-font-stack: #{$montserrat-font-stack};
  --link-font-size: 18px;
  --link-font-style: normal;
  --link-font-variant: normal;
  --link-font-weight: #{get-montserrat-weight(regular)};
  --link-letter-spacing: -.005em;
  --link-line-height: 22px;
  --link-margin-bottom: 0;
  --link-margin-top: 15px;

  @include portrait {
    --link-line-height: 1.75em;
  }

  @include landscape {
    //
  }
}
