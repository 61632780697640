
.thumbnail-list {
  color: $color-white;
  position: relative;

  .thumbnail-list__title {
    @include typography(heading-6);

    margin-bottom: .5rem;
  }

  .thumbnail-list__title-icon {
    display: inline-block;
    height: var(heading-6-line-height);
    vertical-align: top;
    width: var(heading-6-line-height);

    > svg {
      display: inline-block;
      height: 1.1rem;
      vertical-align: top;
      width: 1.1rem;
    }
  }

  .thumbnail-list__thumbnails {
    display: block;
    font-size: 0;
    margin-bottom: -.5rem;
  }

  .thumbnail-list__thumbnail {
    appearance: none;
    background-color: currentcolor;
    border: 1px solid currentcolor;
    display: inline-block;
    height: 3.5rem;
    margin: 0 .5rem .5rem 0;
    transition: border-color $easing-change, border-width $easing-change;
    vertical-align: top;
    width: 3.5rem;

    .picture {
      background-color: $color-white;
      height: 100%;
      width: 100%;
    }

    &:focus {
      outline-color: var(--theme-color);
    }

    .panorama-viewer &,
    .object-viewer & {
      &.thumbnail-list__thumbnail--active {
        border-width: 5px;
        color: var(--theme-color);
        cursor: initial;
        pointer-events: none;
      }
    }
  }
}
