
:root {
  --heading-3-font-stack: #{$montserrat-font-stack};
  --heading-3-font-size: 23px;
  --heading-3-font-style: normal;
  --heading-3-font-variant: normal;
  --heading-3-font-weight: #{get-montserrat-weight(bold)};
  --heading-3-letter-spacing: 0;
  --heading-3-line-height: 30px;
  --heading-3-margin-bottom: 15px;
  --heading-3-margin-top: 0;
  --heading-3-color: #{var(--theme-color)};
  --heading-3-text-transform: uppercase;

  @include landscape {
    --heading-3-font-size: 32px;
    --heading-3-line-height: 42px;
  }
}
