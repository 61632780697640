
.relevant-cards {
  position: relative;
}

.relevant-cards--stories {
  margin-bottom: var(--section-space);

  .relevant-cards__grid {
    @include grid-container;
  }

  .relevant-cards__title {
    @include typography(heading-2);

    color: var(--theme-color);
    grid-column: auto / span 12;
    margin-bottom: 40px;
    transform: translate3d(0, 0, 0);
    transform-origin: bottom left;
  }

  .relevant-cards__item {
    --grid-column-landscape: auto / span 4;

    align-items: center;
    display: flex;

    &:nth-child(1) {
      grid-column: column 1 / span 7;
    }

    &:nth-child(2) {
      grid-column: column 6 / span 7;
      margin-top: -100px;
    }

    &:nth-child(n+3) {
      grid-column: column 1 / span 7;
      margin-top: 20px;
    }

    .card--story .card__content-wrapper {
      --grid-columns: 7;

      @include subgrid-container;
    }

    .card--story .card__content-inner {
      grid-column: auto / span 5;
    }
  }

  @include landscape {
    .relevant-cards__item {
      grid-column: var(--grid-column-landscape);

      &:nth-child(1) {
        grid-column: var(--grid-column-landscape);
      }

      &:nth-child(2) {
        grid-column: var(--grid-column-landscape);
        margin-top: 0;
      }

      &:nth-child(n+3) {
        grid-column: var(--grid-column-landscape);
      }

      .card--story .card__content-wrapper {
        --grid-columns: 4;
      }

      .card--story .card__content-inner {
        grid-column: auto / span 3;
      }
    }
  }
}

.relevant-cards--objects {
  margin-bottom: var(--section-space);

  .relevant-cards__title {
    @include typography(heading-2);

    color: var(--theme-color);
    grid-column: auto / span 12;
  }

  .relevant-cards__title--gradient {
    background-image: linear-gradient($color-white 40%, rgb(255 255 255 / .1%));
    display: inline-block;
    min-width: 50vw;
    padding: 6px 0;
  }

  .relevant-cards__grid {
    @include grid-container;
  }

  .relevant-cards__item {
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) { // 4th5,6, 7th, 10th etc
      grid-row: 2;
    }

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      grid-row: 3;
    }

    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12) {
      grid-row: 4;
    }

    &:nth-child(3n+1) {
      grid-column: column 1 / span 7;
    }

    &:nth-child(3n+2) {
      grid-column: column 6 / span 7;
      margin-top: -150px;
    }

    &:nth-child(3n+3) {
      grid-column: column 1 / span 7;
      margin-top: 20px;
    }
  }

  .card--object {
    .card__content-wrapper {
      --grid-columns: 7;

      @include subgrid-container;
    }

    .card__content-inner {
      grid-column: auto / span 5;
    }
  }

  .relevant-cards__load-more {
    text-align: center;

    .button__span {
      text-transform: uppercase;
    }
  }

  .relevant-cards__item-load-more {
    margin-bottom: 40px;

    &:nth-child(3n+1) {
      grid-column: column 1 / span 7;
    }

    &:nth-child(3n+2) {
      grid-column: column 6 / span 7;
      margin-top: -150px;
    }

    &:nth-child(3n+3) {
      grid-column: column 1 / span 7;
      margin-top: 20px;
    }

    .card--object {
      .card__content-wrapper {
        --grid-columns: 7;

        @include subgrid-container;
      }

      .card__content-inner {
        grid-column: auto / span 5;
      }
    }
  }

  @include landscape {
    .relevant-cards__title {
      line-height: calc(var(--heading-3-line-height) / 2 + 12px);
      margin-bottom: 0;
      transform: translateY(50%);
      z-index: $z-index-over-image;
    }

    .relevant-cards__item {
      grid-column: auto / span 4;
      grid-row: 1;

      &:nth-child(3n+1) {
        grid-column: column 1 / span 4;
      }

      &:nth-child(3n+2) {
        grid-column: column 4 / span 4;
        margin-top: 50px;
      }

      &:nth-child(3n+3) {
        grid-column: column 9 / span 4;
        margin-top: 0;
      }
    }

    .card--object {
      .card__content-wrapper {
        --grid-columns: 4;
      }

      .card__content-inner {
        grid-column: auto / span 3;
      }
    }

    .relevant-cards__grid {
      &.relevant-cards__grid--variation {
        .relevant-objects__item-load-more:nth-child(6n+1) {
          transform: translateY(390px);
        }

        .relevant-objects__item-load-more:nth-child(6n+2) {
          transform: translateY(-260px);

          @media screen and (min-width: 1250px) {
            transform: translateY(-350px);
          }
        }

        .relevant-objects__item-load-more:nth-child(6n+3) {
          transform: translateY(-80px);
        }

        .relevant-objects__item-load-more:nth-child(6n+4) {
          transform: translateY(100px);
        }

        .relevant-objects__item-load-more:nth-child(6n+5) {
          transform: translateY(0px);
        }

        .relevant-objects__item-load-more:nth-child(6n+6) {
          transform: translateY(-250px);
        }
      }
    }

    .relevant-cards__item-load-more {
      grid-column: auto / span 4;

      &:nth-child(3n+1) {
        grid-column: column 1 / span 4;
        margin-bottom: -70px;
      }

      &:nth-child(3n+2) {
        grid-column: column 4 / span 4;
        margin-top: 50px;
      }

      &:nth-child(3n+3) {
        grid-column: column 9 / span 4;
        margin-top: 0;
      }

      .card--object {
        .card__content-wrapper {
          --grid-columns: 4;
        }

        .card__content-inner {
          grid-column: auto / span 3;
        }
      }
    }
  }
}

// Keep this dirty variant name since it's already in use -EKL
.relevant-objects--home {
  height: 100vh;
  margin-bottom: 0;
  padding-bottom: var(--section-space);

  .relevant-cards__title {
    margin-top: 10vh;
  }

  @include landscape {
    .card--object {
      .card__picture {
        height: 60vh;
        max-height: 450px;
      }
    }
  }

  @include desktop {
    .card--object {
      .card__picture {
        max-height: 550px;
      }
    }
  }
}

.relevant-cards--news {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 30px;

  .relevant-cards__grid {
    @include grid-container;

    .link {
      align-items: center;
      color: var(--theme-color);
      display: flex;
      grid-column: auto / span 12;
      justify-content: flex-end;
      line-height: initial;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  .relevant-cards__title {
    @include typography(heading-2);

    color: var(--theme-color);
    grid-column: auto / span 12;
    margin-bottom: 20px;
  }

  .relevant-cards__title--gradient {
    background-image: linear-gradient($color-white 40%, rgb(255 255 255 / .1%));
    display: inline-block;
    min-width: 50vw;
    padding: 6px 0;
  }

  .relevant-cards__item {
    grid-column: auto / span 12;

    &:nth-child(3) {
      display: none;
    }
  }

  @include landscape {
    .relevant-cards__item {
      grid-column: auto / span 4;

      &:nth-child(3) {
        display: block;
      }
    }
  }
}
