
.hero-image-title {
  margin-bottom: var(--section-space);

  .hero-image-title__grid {
    @include grid-container;
  }

  .hero-image-title__image {
    grid-column: auto / span 11;
    mask-image: linear-gradient(to bottom, rgb(0 0 0 / 100%) 50%, rgb(255 255 255 / .1%) 100%);
  }

  .hero-image-title__title-link-wrapper {
    grid-column: column 2 / span 11;
    margin-top: -24px;
    z-index: $z-index-over-image;
  }

  .hero-image-title__title {
    @include typography(heading-2, $margin-top: true, $margin-bottom: true);

    color: var(--theme-color);
  }

  .hero-image-title__link-wrapper {
    .link {
      margin-top: 10px;
    }
  }

  @include landscape {
    .hero-image-title__image {
      grid-column: column 1 / span 8;
      grid-row: 1;
      mask-image: linear-gradient(to right, rgb(0 0 0 / 100%) 50%, rgb(255 255 255 / .1%) 100%);
    }

    .hero-image-title__title-link-wrapper {
      grid-column: column 8 / span 5;
      grid-row: 1;
      margin-top: 40px;
    }

    .hero-image-title__link-wrapper {
      --grid-columns: 5;

      @include subgrid-container;

      .link {
        grid-column: column 2 / span 4;
        margin-top: 20px;
      }
    }
  }
}
