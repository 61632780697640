
.card {
  position: relative;
}

.card--story {
  background: linear-gradient(to top, var(--theme-color) 80%, rgb(255 255 255 / .1%) 80%);
  transition: filter var(--transition-speed);

  &:hover {
    filter: brightness(105%);

    .card__picture-wrapper {
      transform: scale(.95);
    }

    .card__picture {
      transform: scale(1.09);
    }
  }

  .card__inner {
    display: block;
    font-family: $montserrat-font-stack;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 10px;
  }

  .card__label {
    --label-margin-bottom: 10px;

    @include typography(label, false, true, false);

    color: $color-white;
    display: block;
  }

  .card__picture-wrapper {
    display: inline-block;
    margin: 0 15px;
    overflow: hidden;
    transition: var(--transition-speed);
    width: calc(100% - 30px);

    @include landscape {
      margin: 0 15px 20px;
    }
  }

  .card__picture {
    transition: var(--transition-speed);

    img {
      width: 100%;
    }
  }

  .card__content-wrapper {
    display: block;
    padding: 20px 15px;
  }

  .card__title {
    --heading-4-font-size: 18px;
    --heading-4-line-height: 22px;

    @include landscape {
      --heading-4-line-height: 28px;
      --heading-4-font-size: 24px;
    }

    @include typography(heading-4);

    color: $color-white;
    display: block;
  }
}

.card--object {
  &:hover {
    .card__picture-wrapper__inner {
      transform: scale(.95);
    }

    .card__picture {
      filter: brightness(100%);
      transform: scale(1.09);
    }
  }

  .card__inner {
    display: block;
  }

  .card__label {
    @include typography(label, false, true, false);

    color: $color-black;
    display: block;
    font-weight: 600;
  }

  .card__picture-wrapper {
    display: block;
    position: relative;

    &::after {
      background-image: linear-gradient(rgb(255 255 255 / .1%) 10%, $color-white);
      bottom: -.5px;
      content: '';
      height: 114px;
      left: 24px;
      position: absolute;
      right: -.5px;
    }
  }

  .card__picture-inner {
    display: inline-block;
    margin-left: 24px;
    overflow: hidden;
    position: relative;
    transition: var(--transition-speed);
  }

  .card__picture {
    filter: brightness(105%);
    transition: var(--transition-speed);
  }

  .card__content-wrapper {
    display: block;
    margin-top: -36px;
    position: relative;
    z-index: 2;

    @include landscape {
      margin-top: -53px;
    }
  }

  .card__content-inner {
    display: block;
  }

  .card__title {
    --heading-4-font-size: 18px;
    --heading-4-line-height: 22px;

    @include typography(heading-4);

    color: var(--theme-color);
    display: block;
    padding-bottom: 5px;

    @include landscape {
      --heading-4-line-height: 28px;
      --heading-4-font-size: 24px;
    }
  }

  .card__subtitle {
    color: $color-black;
    font-size: 16px;
    line-height: 18px;
  }
}

.card--news {
  margin-bottom: var(--section-space);

  .card__inner {
    color: $color-black;

    @include subgrid-container;

    @include portrait {
      --grid-columns: 4;
    }
  }

  .card__picture-wrapper {
    grid-column: column 7  / span 6;
    position: relative;

    @include portrait {
      grid-column: column 3  / span 2;
    }

    &::after {
      --gradient-height: 42px;

      background-image: linear-gradient(transparent, $color-white);
      bottom: -.5px;
      content: '';
      height: var(--gradient-height);
      left: -.5px;
      position: absolute;
      right: -.5px;

      @include portrait {
        --gradient-height: 56px;
      }
    }
  }

  .card__content-wrapper {
    grid-column: auto  / span 10;
    margin-top: -37px;
    position: relative;
    z-index: 2;

    @include portrait {
      grid-column: auto  / span 3;
      margin-top: -58px;
    }
  }

  .card__label {
    --label-margin-bottom: 4px;

    @include typography(label, false, true, false);

    display: block;
  }

  .card__title {
    @include typography(heading-4, false, false, false);

    color: var(--theme-color);
    display: block;
    margin-bottom: 4px;

    @include portrait {
      margin-bottom: 20px;
    }
  }

  .card__excerpt {
    @include typography(paragraph, $lora-font-stack, false);

    display: block;

    @include portrait {
      --paragraph-font-size: 16px;
      --paragraph-line-height: 22px;
    }
  }

  .card__date {
    @include typography(date, false, false, false);

    display: block;
    margin-top: 4px;

    @include portrait {
      margin-top: 20px;
    }
  }
}
