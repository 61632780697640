
.explore-intro {
  margin-bottom: var(--section-space);

  .explore-intro__grid {
    @include grid-container;
  }

  .explore-intro__title {
    @include typography(heading-1, true, true, false);

    color: var(--theme-color);
    grid-column: auto / span 12;
  }

  .explore-intro__search-form {
    grid-column: auto / span 12;
  }

  .explore-intro__search-wrapper {
    @include subgrid-container;
  }

  .explore-intro__search-input {
    border: 2px solid transparent;
    border-bottom-color: var(--theme-color);
    box-sizing: border-box;
    color: var(--theme-color);
    font-family: $montserrat-font-stack;
    font-size: 18px;
    grid-column: auto / span 8;
    padding: 5px 0;
    width: 100%;

    &::placeholder {
      color: var(--theme-color);
      font-family: $montserrat-font-stack;
      font-size: 18px;
    }

    &:focus {
      border: 2px solid var(--theme-color);
    }
  }

  .explore-intro__search-button-wrapper {
    align-items: flex-end;
    display: flex;
    grid-column: auto / span 4;
  }

  .explore-intro__search-button {
    align-items: center;
    color: var(--theme-color);
    display: flex;
    font-size: 16px;
    line-height: 18px;
    margin: -1rem;
    padding: 1rem;
    text-transform: uppercase;
  }

  .explore-intro__filter-wrapper {
    grid-column: auto / span 12;
    margin-bottom: -.5rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .explore-intro__filter {
    @include typography(introduction);

    color: var(--theme-color);
    display: inline-block;
    margin: 0 .5rem .5rem 0;
    vertical-align: top;
  }

  .explore-intro__filter--active,
  .explore-intro__filter:hover {
    .link__span {
      border-bottom: 2px solid var(--theme-color);
    }
  }

  .explore-intro__search-label {
    @include typography(field-label, false, true, false);

    cursor: pointer;
    display: block;
    line-height: 32px;
    margin: 8px 0;
    user-select: none;
    width: 100%;
  }

  .explore-intro__subfilter-wrapper {
    display: block;
    font-size: 0;
    grid-column: auto/span 12;
    margin-bottom: -.5rem;

    .dropdown-field {
      margin: 0 .5rem .5rem 0;
    }

    .dropdown-field__input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 7.5rem;
    }
  }

  .explore-intro__submit-button {
    align-items: center;
    color: $color-black;
    display: inline-flex;
    font-size: 16px;
    line-height: 1rem;
    margin: 0 .5rem 0 0;
    padding: .5rem 0;
    text-transform: uppercase;
    transition: color $easing-change;

    .link__span {
      font-weight: normal;
    }

    //&:hover,
    //&:focus {
    //  color: var(--theme-color);
    //}
  }

  .explore-intro__clear-button {
    align-items: center;
    color: $color-black;
    display: inline-flex;
    font-size: 16px;
    line-height: 1rem;
    margin: 0 .5rem 0 0;
    padding: .5rem 0;
    text-transform: uppercase;
    transition: color $easing-change;

    //&:hover,
    //&:focus {
    //  color: var(--theme-color);
    //}
  }

  .explore-intro__filter-text-wrapper {
    grid-column: column 1 / span var(--grid-columns);
    margin: 0 0 2rem;
  }

  .explore-intro__filter-text {
    @include typography(heading-6);
  }

  .explore-intro__submit-button,
  .explore-intro__clear-button {
    border: 2px solid transparent;
    padding: calc(.5rem - 1px) .5rem;

    &:hover,
    &:focus {
      border-color: currentcolor;
    }
  }

  @include mobile-only {
    .explore-intro__subfilter-wrapper {
      .dropdown-field,
      .dropdown-field__wrapper,
      .dropdown-field__container {
        display: block;
        width: 100%;
      }

      .dropdown-field__input {
        //border-width: 0;
        //padding-left: 0;
        width: 100%;
      }
    }
  }

  @include landscape {
    .explore-intro__search-input {
      grid-column: auto / span 7;
    }

    .explore-intro__search-button-wrapper {
      grid-column: auto / span 5;
    }

    .explore-intro__submit-button {
      margin-left: .5rem;
    }

    .explore-intro__filter-text-wrapper {
      grid-column: column 1 / span 6;
    }
  }
}
