
.highlighted-card {
  background-color: var(--theme-color);
  color: $color-white;

  .highlighted-card__grid {
    @include grid-container;

    height: 100vh;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .highlighted-card__inner {
    display: flex;
    flex-direction: column;
    grid-column: auto / span 12;
    justify-content: space-between;
  }

  .highlighted-card__title {
    @include typography(heading-2);

    color: $color-white;
  }

  .highlighted-card__card-wrapper {
    @include subgrid-container;

    grid-column: auto / span 12;
  }

  .highlighted-card__card-title {
    @include typography(heading-3, false, false, false);

    color: inherit;
    grid-column: auto / span 9;
    margin-bottom: -15px;
    position: relative;
    z-index: $z-index-over-gradient;
  }

  .highlighted-card__card-content {
    grid-column: auto / span 12;

    .link {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      line-height: initial;
      margin-top: 20px;
      text-transform: uppercase;
    }
  }

  .highlighted-card__card-excerpt {
    @include typography(paragraph, false);

    color: inherit;
  }

  .picture {
    grid-column: auto / span 12;
    margin-bottom: 20px;

    &::before {
      background: linear-gradient(to bottom, rgb(0 0 0 / 50%) 0%, rgb(255 255 255 / 10%) 100%);
      content: '';
      height: 50px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $z-index-over-image;
    }
  }

  .highlighted-card__link-wrapper {
    display: flex;
    grid-column: auto / span 12;
    justify-content: flex-end;

    .link {
      display: none;
    }
  }

  @include landscape {
    .highlighted-card__card-title {
      grid-column: auto / span 6;
      grid-row: 1;
      margin-bottom: -21px;
    }

    .highlighted-card__card-content {
      grid-column: auto / span 3;
      grid-row: 2;

      .link {
        justify-content: initial;
      }
    }

    .highlighted-card__card-excerpt {
      --paragraph-font-size: 16px;
      --paragraph-line-height: 22px;
    }

    .picture {
      grid-column: auto / span 6;
      grid-row: 2;
      margin-bottom: 0;
      max-height: 50vh;
    }

    .highlighted-card__link-wrapper {
      align-items: flex-end;
      grid-column: auto / span 3;
      grid-row: 2;

      .link {
        align-items: center;
        display: flex;
        line-height: initial;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
  }
}
