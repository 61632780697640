
.article-form {
  margin-block-end: var(--block-margin);
  margin-block-start: var(--block-margin);
  margin-bottom: var(--section-space);
  position: relative;

  .article-form__grid {
    @include grid-container;
  }

  .article-form__wrapper {
    grid-column: column 1 / span 12;
    padding-inline-end: var(--grid-padding);
    padding-inline-start: var(--grid-padding);
  }

  @include landscape {
    .article-form__wrapper {
      grid-column: column 3 / span 7;
    }
  }
}
