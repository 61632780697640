
.hero-text {
  align-items: flex-end;
  background-color: var(--theme-color);
  color: $color-white;
  display: flex;
  font-family: $montserrat-font-stack;
  margin-bottom: var(--section-space);
  padding-bottom: 40px;
  padding-top: 40px;

  &:first-child {
    min-height: calc(100vh - var(--header-height));
  }

  .hero-text__grid {
    @include grid-container;

    width: 100%;
  }

  .hero-text__inner {
    grid-column: auto / span 12;
  }

  .hero-text__title {
    @include typography(heading-1, false, true, false);
  }

  .hero-text__text {
    @include typography(introduction, false, true, false);
  }

  .hero-text__meta {
    grid-column: auto / span 12;
  }

  .hero-text__meta__date,
  .hero-text__meta__author {
    display: block;
    font-size: 14px;
    line-height: 150%;
  }

  .thumbnail-list {
    margin-bottom: 1rem;

    .thumbnail-list__thumbnail--active {
      border-color: $color-white;
      border-width: 1px;
    }
  }

  @include landscape {
    .hero-text__inner {
      grid-column: auto / span 11;
    }

    .hero-text__meta {
      grid-column: column 2 / span 11;
    }
  }
}
