
.content-and-link {
  margin-bottom: var(--section-space);

  .content-and-link_grid {
    @include grid-container;
  }

  .content-and-link__link {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    grid-column: column 2 / span 12;

    .link {
      margin-top: var(--section-space);
    }
  }

  .content-and-link__text {
    grid-column: auto / span 12;
  }

  @include landscape {
    .content-and-link__link {
      grid-column: column 9 / span 4;
    }

    .content-and-link__text {
      grid-column: column 2 / span 6;
    }
  }
}
