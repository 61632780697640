
// Base colors
$color-black: #000;
$color-white: #fff;

// Theme colors
$color-blue: #00f;
$color-purple: #8700ff;
$color-pink: #d200c8;
$color-magenta: #eb003c;

// OLD Darkened theme colors
$color-pink-dark: #f2026a;
$color-blue-dark: #11717e;
$color-green-dark: #16a25e;

// Greyscale colors
$color-grey-extradark: #434343;
$color-grey-dark: #696969;
$color-grey-medium: #787878;
$color-grey: #a1a1a1;
$color-grey-light: #f3f1ee;
$color-grey-extralight: #f6f6f6;
$color-grey-new: #ebebeb;

// Functional colors
$color-error: #ff4735;

// Transparencies
$transparency-overlay: rgba($color-white, .8);

// Beziers
$bezier-fancy: cubic-bezier(.26, .88, .57, .9);

// Easings
$easing-change: .15s $bezier-fancy;
$easing-change-slower: .35s $bezier-fancy;
$easing-change-faster: .05s $bezier-fancy;
$easing-change-extraslow: 1s $bezier-fancy;
$easing-linear: .15s linear;

// Layers / z-indexes
$z-index-hide: -1;
$z-index-over-image: 2;
$z-index-over-gradient: 3;
$z-index-menu-overlay: 2048;
$z-index-full-screen: 3000;

// Text shadows
//$text-shadow-on-photo: 1px 1px 15px rgba(0, 0, 0, .15);

// Exposed variables
:root {
  // None yet
  //--bezier-fancy: $bezier-fancy;
  --color-blue: #{$color-blue};
  --color-purple: #{$color-purple};
  --color-pink: #{$color-pink};
  --color-magenta: #{$color-magenta};
  --theme-color: var(--color-magenta);
  --header-height: 62px;

  @include landscape {
    --header-height: 65px;
  }

  .theme--blue {
    --theme-color: var(--color-blue);
  }

  .theme--purple {
    --theme-color: var(--color-purple);
  }

  .theme--pink {
    --theme-color: var(--color-pink);
  }

  .theme--magenta {
    --theme-color: var(--color-magenta);
  }
}
