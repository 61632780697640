
// Keyframe animations
// NOTE: Keyframe names cannot contain dashes or underscores, or some browsers will be quite upset.

@keyframes infobuttonicon {
  0% {
    transform: translateX(.25rem);
  }

  50% {
    transform: translateX(.25rem);
  }

  65% {
    transform: translateX(-.25rem);
  }

  75% {
    transform: translateX(-.25rem);
  }

  100% {
    transform: translateX(.25rem);
  }
}
