
:root {
  --field-input-font-stack: #{$montserrat-font-stack};
  --field-input-font-size: 16px;
  --field-input-font-style: normal;
  --field-input-font-variant: normal;
  --field-input-font-weight: #{get-montserrat-weight(regular)};
  --field-input-letter-spacing: -.005em;
  --field-input-line-height: 1.25em;
  --field-input-margin-bottom: .5em;
  --field-input-margin-top: 1em;

  @include portrait {
    --field-input-font-size: 18px;
    --field-input-line-height: 1.75em;
  }

  @include landscape {
    //
  }
}
