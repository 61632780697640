
.article-content {
  margin-bottom: var(--section-space);

  .article-content__grid {
    @include grid-container;
  }

  .article-content__wrapper {
    grid-column: column 1 / span 12;
  }

  @include landscape {
    .article-content__wrapper {
      grid-column: column 3 / span 7;
    }
  }
}
