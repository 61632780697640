
.dropdown-field {
  color: var(--theme-color);
  display: inline-block;
  position: relative;
  vertical-align: top;

  .dropdown-field__label {
    @include typography(field-label);

    cursor: pointer;
    display: block;
    line-height: 2rem;
    margin: 1rem 0;
    user-select: none;
  }

  .dropdown-field__wrapper {
    display: inline-block;
    font-size: 0;
    max-width: 100%;
    position: relative;
    vertical-align: top;
  }

  .dropdown-field__input {
    @include typography(field-input);

    background-color: transparent;
    border: 2px solid currentcolor;
    border-radius: 0;
    color: currentcolor;
    display: inline-block;
    flex: 1 1 100%;
    height: 2rem;
    line-height: 1rem;
    margin: 0;
    min-height: 2rem;
    min-width: 2rem;
    overflow: hidden;
    padding: 0 1.75rem 0 .5rem;
    position: relative;
    transition: color .25s ease-out, background-color .25s ease-out;
    vertical-align: top;
    white-space: nowrap;
    width: auto;

    &:not(:disabled):focus-visible {
      outline-color: $color-black;
      //outline: 2px solid var(--theme-color, currentcolor);
      //outline-offset: 2px;
    }

    //&:hover:not(:disabled) {
    //  color: $color-black;
    //}

    + .dropdown-field__button {
      background-color: transparent;

      &:hover {
        background-color: transparent;
        color: inherit;
      }
    }

    &:disabled {
      opacity: .4;
      pointer-events: none;
    }
  }

  .dropdown-field__icon {
    border: .5rem solid transparent;
    fill: currentcolor;
    height: 2rem;
    line-height: 2rem;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 2rem;
    z-index: 1;
  }

  &.dropdown-field--required {
    .dropdown-field__label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &:not(.dropdown-field--required) {
    .dropdown-field__label {
      &::after {
        content: '';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &.dropdown-field--error {
    .dropdown-field__label {
      color: $color-error;
    }

    .dropdown-field__error {
      @include typography(paragraph);

      color: $color-error;
      display: inline-block;
      max-width: 100%;
      width: auto;
    }
  }
}
