
.list-expander {
  margin-bottom: var(--section-space);

  .list-expander__grid {
    @include grid-container;
  }

  .list-expander__wrapper {
    grid-column: column 1 / span 12;
  }

  .list-expander__list {
    @include style-list-base;
    @include style-list-unordered;

    display: none;
  }

  .list-expander__extra-content {
    .list-expander__list {
      margin-top: 20px;
    }
  }

  .button--expand {
    color: var(--theme-color);
    margin-top: 20px;

    &:focus {
      overflow: hidden;
    }

    .button__icon--after {
      transition: .25s ease-in-out transform;
    }

    .button__icon > svg {
      fill: var(--theme-color);
    }
  }

  &.list-expander--expanded {
    .button--expand {
      .button__icon--after {
        transform: scaleY(-1);
      }
    }
  }

  @include landscape {
    .list-expander__wrapper {
      grid-column: column 3 / span 7;
    }
  }
}
