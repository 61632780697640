
.hero-image {
  background-color: var(--theme-color);

  .hero-image__wrapper {
    position: relative;

    > .picture {
      .picture__image {
        height: calc(82vh - var(--header-height));
        object-fit: cover;
      }
    }

    .multiple-panorama-viewers__thumbnail-list {
      display: none;
    }
  }
}
