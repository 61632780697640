
.content-expander {
  margin-bottom: var(--section-space);

  .content-expander__grid {
    @include grid-container;
  }

  .content-expander__wrapper {
    grid-column: column 1 / span 12;
  }

  .content-expander__content,
  .content-expander__extra-content {
    margin-bottom: 20px;
  }

  .button--expand {
    color: var(--theme-color);
    margin-top: 20px;

    &:focus {
      overflow: hidden;
    }

    .button__icon--after {
      transition: .25s ease-in-out transform;
    }

    .button__icon > svg {
      fill: var(--theme-color);
    }
  }

  &.content-expander--expanded {
    .button--expand {
      .button__icon--after {
        transform: scaleY(-1);
      }
    }
  }

  @include landscape {
    .content-expander__wrapper {
      grid-column: column 3 / span 7;
    }
  }
}
