
.object-detail-aside-image {
  position: relative;

  &::after {
    background-image: linear-gradient(rgb(255 255 255 / .1%), $color-white);
    bottom: 0;
    content: '';
    height: 70px;
    left: 0;
    position: absolute;
    right: 0;
  }

  .object-detail-aside-image__picture-wrapper {
    position: relative;

    .picture__image {
      max-height: 75vh;
    }
  }

  @include landscape {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
    padding-top: 0;
    position: sticky;
    top: 65px;

    > *:first-child {
      margin-top: auto;
    }

    &::after {
      display: none;
    }
  }
}
